import create from 'zustand';
import { persist } from 'zustand/middleware';

import StorageService from 'lib/services/Storage';
import { IApplication, Location } from 'lib/types';
import { FlowResponse } from 'pages/AuthChecker/type';

export enum LanguageEnum {
  'EN' = 'en',
  'ES' = 'es',
}

interface Organization {
  active: boolean;
  flow: string;
  id: string;
  isDemo: boolean;
  name: string;
  outcomeThreshold: string;
  roles: string[];
  slug: string;
  website: string;
  phone: string;
}

interface Merchant {
  id: string;
  name: string;
}

interface OrganizationGroupOrganization {
  id: string;
  name: string;
  slug: string;
  merchants: Merchant[];
}

interface OrganizationGroup {
  id: string;
  name: string;
  organizations: OrganizationGroupOrganization[];
}

interface SessionData {
  phone: string;
  otp: string;
}

interface Address {
  address1: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  city: any;
  zip: string;
}

interface Borrower {
  address: Address;
  bankNames: string[];
  createdAt: string;
  dob: string;
  email: string;
  firstName: string;
  id: number;
  idNumber: string;
  kycFailedReason: string;
  kycStatus: string;
  lastName: string;
  phone: string;
  ssnUW: string;
  status: string;
  verificationRequired: boolean;
}

interface ApplyWithPatientBorrower {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phone: string;
  last4ssn: string;
  streetAddress: string;
  aptUnitAddress: string;
  city: string;
  state: string;
  zip: string;
  statedIncome: string;
  housingPayment: string;
  requestAmount: string;
}

const initialSessionData = {
  phone: '',
};

const initialApplyWithPatientBorrower = {
  firstName: '',
  lastName: '',
  dob: '',
  email: '',
  phone: '',
  last4ssn: '',
  streetAddress: '',
  aptUnitAddress: '',
  city: '',
  state: '',
  zip: '',
  statedIncome: '',
  housingPayment: '',
  requestedAmount: '',
};

interface Store {
  appLoading: boolean;
  setAppLoading: (value: boolean) => void;

  defaultLanguage: LanguageEnum;
  setDefaultLanguage: (value: string) => void;

  signOut: () => void;

  isDemo: boolean;
  setIsDemo: (value: boolean) => void;

  flowType: string | null;
  setFlowType: (value: string) => void;

  pageIndex: number;
  setPageIndex: (value: number) => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  user: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setUser: (user: any) => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  features: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setFeatures: (features: any) => void;

  organization: Organization;
  setOrganization: (organization: Organization) => void;

  organizationGroup: OrganizationGroup;
  setOrganizationGroup: (organizationGroup: OrganizationGroup) => void;

  sessionData: SessionData;
  setSessionData: (sessionData: SessionData) => void;

  borrower: Borrower;
  setBorrower: (borrower: Borrower) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  authData: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setAuthData: (authData: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  sessionApiData: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setSessionApiData: (sessionApiData: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  cliMissingInfo: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setCliMissingInfo: (cliMissingInfo: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  loan: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setLoan: (loan: any) => void;

  type: string;
  setType: (type: string) => void;

  partner: string;
  setPartner: (partner: string) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  productCalculations: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setProductCalculations: (productCalculations: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  paymentMethods: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setPaymentMethods: (paymentMethods: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  paymentMethodFees: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setPaymentMethodFees: (paymentMethodFees: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  downPaymentMethod: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setDownPaymentMethod: (downPaymentMethod: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  installmentMethod: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setInstallmentMethod: (installmentMethod: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  agreement: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setAgreement: (agreement: any) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedPlan: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setSelectedPlan: (selectedPlan: any) => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  reviewOutcome: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setReviewOutcome: (reviewOutcome: any) => void;

  selectedPaymentPlan: number;
  setSelectedPaymentPlan: (selectedPaymentPlan: number) => void;

  selectedAutopay: string;
  setSelectedAutopay: (selectedAutopay: string) => void;

  utmSource: string;
  setUtmSource: (utmSource: string) => void;

  utmMedium: string;
  setUtmMedium: (utmMedium: string) => void;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  incomeVerification: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setIncomeVerification: (incomeVerification: any) => void;

  showChat: boolean;
  setShowChat: (showChat: boolean) => void;

  isPosSplitPayment: boolean;
  setIsPosSplitPayment: (issSplitPayment: boolean) => void;

  isAlle: boolean;
  setIsAlle: (isAlle: boolean) => void;

  partnerApplicationId: string | number | null;
  setPartnerApplicationId: (partnerApplicationId: string | number) => void;

  paymentFailError: boolean;
  setPaymentFailError: (paymentFailError: boolean) => void;

  applyWithPatientReviewData: ApplyWithPatientBorrower;
  setApplyWithPatientReviewData: (applyWithPatientReviewData: ApplyWithPatientBorrower) => void;

  preCLIBalance: number | undefined;
  setPreCLIBalance: (preCLIBalance: number | undefined) => void;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const useStore = create<Store | any>((set, get) => ({
  appLoading: true,
  setAppLoading: (value: boolean) => set(() => ({ appLoading: value })),
  defaultLanguage: LanguageEnum.EN,
  setDefaultLanguage: (value: LanguageEnum) => set(() => ({ defaultLanguage: value })),

  signOut: () => {
    StorageService.clearUserData();
    set(() => ({ amountOfPayment: 0, paymentMethods: null, loanList: [] }));
    return set(() => ({ borrower: {}, phoneNumber: '', otpNumber: '' }));
  },

  isDemo: false,
  setIsDemo: (value: boolean) => set(() => ({ isDemo: value })),

  flowType: null,
  setFlowType: (value: string) => set(() => ({ flowType: value })),

  pageIndex: 0,
  setPageIndex: (value: number) => set(() => ({ pageIndex: value })),

  declinedCardError: false,
  setDeclinedCardError: (value: boolean) => set(() => ({ declinedCardError: value })),

  pageTitle: '',
  setPageTitle: (value: string) => set(() => ({ pageTitle: value })),

  user: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setUser: (user: any) => set(() => ({ user })),

  features: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setFeatures: (features: any) => set(() => ({ features })),

  organization: { name: '', id: '' },
  setOrganization: (organization: Organization) => set(() => ({ organization })),

  organizationGroup: { id: '', name: '', organizations: [] },
  setOrganizationGroup: (organizationGroup: OrganizationGroup) => set(() => ({ organizationGroup })),

  locations: [],
  setLocations: (locations: Location[]) => set(() => ({ locations })),

  selectedLocation: null,
  setSelectedLocation: (selectedLocation: Location) => set(() => ({ selectedLocation })),

  selectedApplication: null,
  setSelectedApplication: (selectedApplication: IApplication) => set(() => ({ selectedApplication })),

  borrower: {},
  setBorrower: (borrower: Borrower) => set(() => ({ borrower })),

  authData: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setAuthData: (authData: any) => set(() => ({ authData })),

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  sessionApiData: null,

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setSessionApiData: (sessionApiData: any) =>
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    set((state: any) => ({ sessionApiData: { ...state.sessionApiData, ...sessionApiData } })),

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  cliMissingInfo: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setCliMissingInfo: (cliMissingInfo: any) => set(() => ({ cliMissingInfo })),

  loan: {},
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setLoan: (loan: any) => set(() => ({ loan })),

  type: '',
  setType: (type: string) => set(() => ({ type })),

  selectedPaymentPlan: null,
  setSelectedPaymentPlan: (selectedPaymentPlan: number) => set(() => ({ selectedPaymentPlan })),

  selectedAutopay: null,
  setSelectedAutopay: (selectedAutopay: string) => set(() => ({ selectedAutopay })),

  partner: '',
  setPartner: (partner: string) => set(() => ({ partner })),

  productCalculations: [],
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setProductCalculations: (productCalculations: any) => set(() => ({ productCalculations })),

  selectedPlan: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setSelectedPlan: (selectedPlan: any) => set(() => ({ selectedPlan })),

  paymentMethods: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setPaymentMethods: (paymentMethods: any) => set(() => ({ paymentMethods })),

  paymentMethodFees: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setPaymentMethodFees: (paymentMethodFees: any) => set(() => ({ paymentMethodFees })),

  downPaymentMethod: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setDownPaymentMethod: (downPaymentMethod: any) => set(() => ({ downPaymentMethod })),

  installmentMethod: null,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setInstallmentMethod: (installmentMethod: any) => set(() => ({ installmentMethod })),

  agreement: {},
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setAgreement: (agreement: any) => set(() => ({ agreement })),

  reviewOutcome: {},
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setReviewOutcome: (reviewOutcome: any) => set(() => ({ reviewOutcome })),

  sessionData: initialSessionData,
  setSessionData: (value: SessionData) => set((state: Store) => ({ sessionData: { ...state.sessionData, ...value } })),

  application: null,
  setApplication: (value: IApplication) => set((state: Store) => ({ application: value })),

  applicationFlow: null,
  setApplicationFlow: (value: FlowResponse) => set((state: Store) => ({ applicationFlow: value })),

  utmSource: '',
  setUtmSource: (utmSource: string) => set(() => ({ utmSource })),

  utmMedium: '',
  setUtmMedium: (utmMedium: string) => set(() => ({ utmMedium })),

  incomeVerification: null,
  setIncomeVerification: (incomeVerification) => set(() => ({ incomeVerification })),

  showChat: false,
  setShowChat: (showChat: boolean) => set(() => ({ showChat })),

  isPosSplitPayment: false,
  setIsPosSplitPayment: (isPosSplitPayment: boolean) => set(() => ({ isPosSplitPayment })),

  isAlle: false,
  setIsAlle: (isAlle: boolean) => set(() => ({ isAlle })),

  partnerApplicationId: null,
  setPartnerApplicationId: (partnerApplicationId: string | number) => set(() => ({ partnerApplicationId })),

  paymentFailError: false,
  setPaymentFailError: (paymentFailError: boolean) => set(() => ({ paymentFailError })),

  applyWithPatientReviewData: initialApplyWithPatientBorrower,
  setApplyWithPatientReviewData: (applyWithPatientReviewData: ApplyWithPatientBorrower) =>
    set(() => ({ applyWithPatientReviewData })),

  preCLIBalance: undefined,
  setPreCLIBalance: (preCLIBalance: number | undefined) => set(() => ({ preCLIBalance })),
}));

export default useStore;
