import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { theme } from 'config/theme';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useMatchedRoute } from 'lib/hooks/useMatchedRoute';
import useStore, { LanguageEnum } from 'lib/hooks/useStore';

import { BannerProps, ChatWidgetButton, Icon, Title } from 'lib/components';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useMutation } from '@apollo/client';
import { UPDATE_BORROWER } from 'lib/graphql/mutations';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useSegment } from 'lib/hooks/useSegment';
import { ClientErrors } from 'lib/constants';
import { Redirect } from 'lib/utils';

type WrapperProps = {
  children: React.ReactElement;
  show: boolean;
  withSlideAnimation?: boolean;
};

const Wrapper = ({ children, show, withSlideAnimation = true }: WrapperProps) =>
  withSlideAnimation ? (
    <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
      {children}
    </Slide>
  ) : (
    <>{children}</>
  );

interface HelpMeModalProps {
  show: boolean;
  hideModal: () => void;
  emailPressed: (e) => void;
  callPressed: (e) => void;
  logoutPressed: () => void;
  title: string;
  center?: boolean;
  banner?: BannerProps | null;
}

export const HelpMeModal = ({
  show,
  hideModal,
  emailPressed,
  callPressed,
  logoutPressed,
  title,
  center = false,
  banner,
}: HelpMeModalProps) => {
  const { t: translate } = useTranslation();
  const { navigate } = useNavigation();
  const location = useLocation();
  const route = useMatchedRoute();

  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent } = useSegment();
  const { authData, organization, flowType, isAlle, defaultLanguage, setDefaultLanguage } = useStore();
  const [updateBorrower] = useMutation(UPDATE_BORROWER);

  const { slug, token, loanToken } = authData || {};

  const nativeNavigate = useNavigate();
  const { sendActionSegmentEvent } = useSegmentContext();
  const alert = useAlert();

  const pathname = location?.pathname;
  const isCheckoutPage =
    pathname === `/${organization?.slug}/choose-plan` || pathname === `/${organization?.slug}/success`;
  const approvalPageScreens = [
    `/${organization?.slug}/preview-payment`,
    `/${organization?.slug}/approval`,
    `/${organization?.slug}/what-happens-next`,
    `/${organization?.slug}/all-set`,
  ];
  const isApprovalPage = approvalPageScreens.includes(pathname);
  let showStartOverButton = true;
  if ((token || loanToken) && (route?.path === ':id' || isCheckoutPage || isApprovalPage)) {
    showStartOverButton = false;
  }

  if (pathname?.includes('not-found')) {
    showStartOverButton = false;
  }
  const restartApplication = () => {
    sendActionSegmentEvent(WPQSegmentNames.helpModalStartOverClicked);
    if (isAlle) {
      flowType === 'CheckoutFlow' ? navigate(`choose-plan`) : nativeNavigate(`/${slug}`);
    } else if (flowType === 'CheckoutFlow') {
      navigate(`choose-plan`);
    } else {
      logoutPressed();
    }
  };

  const changeLanguageHandler = () => {
    const { borrower } = useStore?.getState() || {};
    const language = defaultLanguage === LanguageEnum.EN ? LanguageEnum.ES : LanguageEnum.EN;
    trackEvent(AnalyticEventNames.CHANGE_LANGUAGE, language);
    trackSegmentEvent(AnalyticEventNames.CHANGE_LANGUAGE, {
      language,
    });
    sendActionSegmentEvent(WPQSegmentNames.helpModalLanguageClicked);

    if (language) {
      if (!borrower?.id) {
        // if no borrowerId, not in checkout flow, not approved --> can change language
        setDefaultLanguage(language);
      } else {
        updateBorrowerLanguage?.(language);
      }
    }
  };

  const updateBorrowerLanguage = async (language: string) => {
    const { borrower } = useStore?.getState() || {};

    const resp = await updateBorrower({
      variables: {
        input: {
          id: borrower?.id,
          language: language?.toUpperCase(),
          source: 'GREET',
        },
      },
    });
    if (resp?.data?.updateBorrower?.success) {
      setDefaultLanguage(language);
    } else {
      const message = resp?.data?.updateBorrower?.message;
      alert.info(ClientErrors[message] || message);
    }
  };

  const navigateFaq = () => {
    sendActionSegmentEvent(WPQSegmentNames.helpModalFaqClicked);
    Redirect(`https://withcherry.com/patient-faq`);
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Wrapper show={show} withSlideAnimation={!center}>
        <HelpMeContainer
          showStartOverButton={showStartOverButton}
          center={center}
          useElegantFontType={isAlle}
          hasBanner={!!banner?.showForPatient}
        >
          <HelpMeHeader center={isAlle}>
            <IconGap />
            <Title
              m={'24px 0px'}
              color={isAlle && theme.main.black10}
              fontSize={isAlle && '24px'}
              fontWeight={isAlle && 400}
              lineHeight={isAlle && '32px'}
              useElegantFontType={isAlle}
            >
              {translate(title)}
            </Title>
            {!isAlle ? <Icon src={'close'} onClick={hideModal} /> : null}
          </HelpMeHeader>

          {banner?.showForPatient ? (
            <BarContainer>
              <HtmlContent dangerouslySetInnerHTML={{ __html: banner?.patientText }} />
            </BarContainer>
          ) : null}

          {isAlle ? (
            <>
              <HelpMeRef onClick={emailPressed}>support@withcherry.com</HelpMeRef>
              <HelpMeRef onClick={callPressed}>(888) 839-7171</HelpMeRef>
            </>
          ) : (
            <>
              <HelpMeButton onClick={navigateFaq}>{translate('header.modal.faq')}</HelpMeButton>
              <HelpMeButton onClick={changeLanguageHandler}>
                <Icon src={'language'} m={'0px 10px'} width={16} />
                {translate('language.code')}
              </HelpMeButton>
              <HelpMeButton onClick={emailPressed}>
                <Icon src={'mail'} m={'0px 10px'} width={16} />
                {translate('header.modal.emailSupport')}
              </HelpMeButton>
              <HelpMeButton onClick={callPressed}>
                <Icon src={'phone'} m={'0px 10px'} width={19} />
                {translate('header.modal.callSupport')}
              </HelpMeButton>
            </>
          )}

          {navigator.cookieEnabled ? <ChatWidgetButton icon="message" /> : null}

          {showStartOverButton && (
            <HelpMeButton onClick={restartApplication} useElegantFontType={isAlle}>
              {!isAlle && <Icon src={'logout'} m={'0px 10px'} width={16} />}
              {translate('header.modal.startOver')}
            </HelpMeButton>
          )}
        </HelpMeContainer>
      </Wrapper>
    </Backdrop>
  );
};

const HelpMeContainer = styled.div<{
  showStartOverButton: boolean;
  center?: boolean;
  useElegantFontType?: boolean;
  hasBanner: boolean;
}>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: ${(props) => (props.center ? '12px 12px 0 0' : '0 0 32px 32px')};
  padding: 0 24px 30px;
  font-family: ${(props) => props.useElegantFontType && 'Graphik'} !important;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: ${(props) => (props.hasBanner ? 85 : 170)}px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;
  }
`;

const HelpMeHeader = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
  align-items: center;
  margin-bottom: 10px;
`;

const HelpMeButton = styled.div<{ useElegantFontType?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main.white};
  border: 1px solid ${theme.main.grayColor};
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
  font-weight: ${(props) => (props.useElegantFontType ? 400 : 600)};
  font-size: ${(props) => (props.useElegantFontType ? '11px' : '18px')};
  text-transform: ${(props) => (props.useElegantFontType ? 'uppercase' : 'none')};
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const HelpMeRef = styled.div`
  font-family: 'Graphik', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.main.alleBlack};
  text-decoration: underline;
  margin-bottom: 24px;
`;

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.main.black};
  align-items: center;
  background: ${(props) => props.theme.main.demoModeColor};
  text-align: center;
  padding: 8px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 12px;

  > div {
    margin-top: 8px;
  }
`;

const HtmlContent = styled.div``;
