import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Redirect } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

import { Button, Container, Icon, Subtitle, SurveyTypeForm, Title } from 'lib/components';
import { theme } from 'config/theme';
import { FaqWhatHappenNext } from 'pages/Faq/components';
import HowToPayWithCherryModal from './HowToPayWithCherryModal';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useViewport } from 'lib/hooks/useViewport';
import { useNavigatePatientPortal } from 'lib/hooks/useNavigatePatientPortal';
import { Stack, Typography } from '@mui/material';
import ApprovalCard from 'pages/Approval/components/ApprovalCard';
import AddWallet from 'pages/Approval/components/AddWallet';
import SendApprovalEmail from 'pages/Approval/components/SendApprovalEmail';
import ThanksForClickingModal from 'pages/Approval/modals/ThanksForClickingModal';
import { PAGE_COUNT } from 'lib/constants';

export const WhatHappensNext = () => {
  const { organization, setPageIndex, application } = useStore();
  const { t: translate } = useTranslation();
  const { goToPatientPortal } = useNavigatePatientPortal();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const [howToPayWithCherryVisibility, setHowToPayWithCherryVisibility] = useState(false);
  const [thanksForClickingModalVisibility, setThanksForClickingModalVisibility] = useState(false);
  const { sessionApiData } = useStore.getState();
  const { featureFlags } = sessionApiData || {};
  const isNewApprovalCardEnable = featureFlags?.newApprovalCard || false;
  const { navigate } = useNavigation();
  const hideHowToPayWithCherryModal = () => setHowToPayWithCherryVisibility(false);
  const { isMobile } = useViewport();
  const isFakeRequestIncreaseExprimentEnabled = featureFlags?.isFakeRequestIncreaseExprimentEnabled || false;

  const hideThanksForClicking = () => setThanksForClickingModalVisibility(false);
  const openHowToPayWithCherryModal = () => {
    sendActionSegmentEvent(WPQSegmentNames.whatHappensNextLearnMoreClicked);
    setHowToPayWithCherryVisibility(true);
  };

  const visitPatientPortal = () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalWhatHappensNextPatientPortalClicked);
    goToPatientPortal();
  };

  useEffect(() => {
    if (loadSegmentController.current) {
      setPageIndex(isFakeRequestIncreaseExprimentEnabled ? PAGE_COUNT : 3);
      sendLoadSegmentEvent(WPQSegmentNames.whatHappensNextLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const onContinue = () => {
    sendActionSegmentEvent(WPQSegmentNames.whatHappensNextContinueClicked);
    navigate('all-set');
  };

  const navigateFaq = () => {
    sendActionSegmentEvent(WPQSegmentNames.whatHappensNextMoreQuestionClicked);
    Redirect(`https://withcherry.com/patient-faq`);
  };

  return (
    <Container showBackButton={true} backUrl={isFakeRequestIncreaseExprimentEnabled ? 'approval' : 'preview-payment'}>
      <ContentContainer isMobile={isMobile}>
        <Title m={'10px 0px 8px 0px'}>{translate('whatHappensNow.new.title')}</Title>
        {!isNewApprovalCardEnable ? (
          <>
            <CustomSubtitle m={'0 0px 24px 0px'}>
              <Trans
                i18nKey={'whatHappensNow.new.subTitle'}
                components={{
                  bold: <strong />,
                }}
                values={{ name: organization?.name }}
              />
            </CustomSubtitle>
            <ApprovalBox>
              <StyledImage src={`/icon/what-happens-now.png`} />
              <ApprovalText>
                <Trans
                  i18nKey={'whatHappensNow.new.sayPayingCherry'}
                  components={{
                    bold: <strong />,
                  }}
                  values={{ name: organization?.name }}
                />
              </ApprovalText>
              <LearnMore onClick={openHowToPayWithCherryModal}>
                <Icon src={'info_circle_green'} width={21} />
                <span>{translate('whatHappensNow.new.learnMore')}</span>
              </LearnMore>
            </ApprovalBox>
          </>
        ) : (
          <>
            <ApprovalCardDescription>
              <Trans
                i18nKey="approvalCard.subtitle"
                values={{ name: organization?.name }}
                components={{
                  bold: <strong />,
                }}
              ></Trans>
            </ApprovalCardDescription>
            <ApprovalCard expireAt={application?.expireAt} balanceAvailable={application?.balanceAvailable} />
            <AddWallet openThanksModal={setThanksForClickingModalVisibility} page="whatHappensNext" />
            <SendApprovalEmail openThanksModal={setThanksForClickingModalVisibility} page="whatHappensNext" />
          </>
        )}
        {!isFakeRequestIncreaseExprimentEnabled && (
          <Button mb={true} onClick={onContinue}>
            {translate('buttons.continue')}
          </Button>
        )}
        {isFakeRequestIncreaseExprimentEnabled && (
          <Stack spacing={3}>
            <PatientPortalDescription>
              {translate('requestLargerApproval.patientPortalDescription')}
            </PatientPortalDescription>
            <Button onClick={visitPatientPortal}>{translate('buttons.visitPatientPortal')}</Button>
            <SurveyTypeForm
              page="APPROVAL"
              title={translate('approval.survey.title', {
                price: '$25',
              })}
              description={translate('approval.survey.description', {
                price: '$25',
              })}
            />
          </Stack>
        )}
        <div>
          <FaqWhatHappenNext />
        </div>
        <Button onClick={navigateFaq} secondary={true}>
          {translate('buttons.haveMoreQuestion')}
        </Button>
      </ContentContainer>
      {howToPayWithCherryVisibility ? (
        <HowToPayWithCherryModal show={true} hideModal={hideHowToPayWithCherryModal} />
      ) : null}
      {thanksForClickingModalVisibility ? (
        <ThanksForClickingModal show={true} hideModal={hideThanksForClicking} />
      ) : null}
    </Container>
  );
};

const StyledImage = styled.img`
  width: 80%;
  height: auto;
  margin: 0 auto;
  padding: 0;
  max-width: 200px;
`;

const CustomSubtitle = styled(Subtitle)`
  font-size: 16px;
  color: ${theme.main.midnightBlue};
`;

const ApprovalText = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.51px;
  text-align: center;
`;

const LearnMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
  span {
    color: ${theme.main.green};
    text-decoration: underline;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
`;

const ApprovalBox = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 24px 16px 24px;
  gap: 16px;
  margin-bottom: 32px;
  border-radius: 24px;
  opacity: 0px;
  border: ${(props) => (!props?.isMobile ? '2px solid #e7e9ea' : '0px')};
  box-shadow: 0 1.23656px 2.30138px 0 rgba(0, 0, 0, 0.02), 0 3.12736px 5.82036px 0 rgba(0, 0, 0, 0.03),
    0 6.37951px 11.87298px 0 rgba(0, 0, 0, 0.04), 0 13.14059px 24.4561px 0 rgba(0, 0, 0, 0.05),
    0 36px 67px 0 rgba(0, 0, 0, 0.07);
`;

const ContentContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 0px 24px 20px 24px;
  margin-top: 0px;
  padding-top: 15px;
  background-color: ${(props) => (props?.isMobile ? theme.main.whiteGray : theme.main.white)};
`;

const ApprovalCardDescription = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 8px 0px 24px;
`;

const PatientPortalDescription = styled(Typography)`
  color: ${theme.main.textColor};
  font-family: 'Open Sans', serif !important;
`;
