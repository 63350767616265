import { useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useViewport } from 'lib/hooks/useViewport';
import { useSentry } from 'lib/hooks/useSentry';
import AllePlans from 'pages/Plans/AllePlans';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import PlanSelection from './PlanSelection';
import { IPlan } from './types';

const Plans = () => {
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { isMobile } = useViewport();
  const { trackEvent } = useAnalytics();
  const { trackOptionSelected } = useSegment();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();
  const {
    organization,
    loan,
    setPageIndex,
    setSelectedPlan,
    productCalculations,
    setSelectedPaymentPlan,
    selectedPaymentPlan,
    isAlle,
    sessionApiData,
  } = useStore();
  const [filteredProductCalculations, setFilteredProductCalculations] = useState<IPlan[]>(productCalculations);

  const { featureFlags } = sessionApiData || {};

  const amount = loan?.purchaseAmount;
  const calculateSelectedIndex = 0;

  const isDownPaymentEnabled = featureFlags?.downPaymentEnabled || false;

  useEffect(() => {
    let productList: IPlan[] = productCalculations;
    if (!isDownPaymentEnabled) {
      productList = productCalculations.filter((calculation) => calculation?.product?.parentId === null);
    }
    setFilteredProductCalculations(productList);
  }, [productCalculations]);

  const [selectedIndex, setSelectedIndex] = useState<number>(selectedPaymentPlan || calculateSelectedIndex);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutSelectionLoad, {
        purchaseAmount: amount,
        application: 'checkout',
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(1);
  }, [setPageIndex]);

  useEffect(() => {
    setSelectedPaymentPlan(selectedIndex);
  }, [selectedIndex]);

  const navigateToPlanReview = () => {
    try {
      const { application } = useStore.getState() || {};
      const selectedPlan = filteredProductCalculations?.[selectedIndex];

      trackOptionSelected(organization, application, loan, selectedPlan, amount);
      sendActionSegmentEvent(`WPQ.OUTCOME.PLAN_SELECTION_${selectedMonth}_MONTH_PLAN_SELECTED`);

      setSelectedPlan(selectedPlan);

      navigate(`plan-review`);
    } catch (err) {
      captureException('Plans Error', {
        error: err,
        page: 'Plans',
        organizationId: organization?.id,
        loanId: loan?.id,
      });
    }
  };

  const handleCarouselChange = (index: number) => {
    trackEvent(AnalyticEventNames.PLAN_PICK);
    setSelectedIndex(index);
    sendActionSegmentEvent(`WPQ.OUTCOME.PLAN_SELECTION_${selectedMonth}_MONTH_PLAN_CLICKED`);
  };

  const handleCustomDownPayment = () => {
    try {
      const selectedPlan = filteredProductCalculations?.[selectedIndex];
      setSelectedPaymentPlan(selectedIndex);
      setSelectedPlan(selectedPlan);
      sendActionSegmentEvent(WPQSegmentNames.customDownPaymentClicked);

      navigate(`customize-plan`);
    } catch (err) {
      captureException('Customize Plan Error', {
        error: err,
        page: 'Plans',
        message: 'Customize Plan Error',
        loanId: loan?.id || 'No Loan',
      });
    }
  };

  // Slider Count
  const sliderDisplayCount = isMobile ? (selectedIndex === 0 ? 1.5 : 1.6) : 2.2;
  const selectedMonth = filteredProductCalculations?.[selectedIndex]?.product?.term;
  const _selectedPlan = filteredProductCalculations?.[selectedIndex];

  const customButtonText = filteredProductCalculations?.[selectedIndex]?.product?.parentId
    ? translate('plans.customTitle')
    : '';

  const renderAlleScreen = () => {
    return (
      <AllePlans
        amount={amount}
        filteredProductCalculations={filteredProductCalculations}
        selectedPlan={_selectedPlan}
        selectedIndex={selectedIndex}
        sliderDisplayCount={sliderDisplayCount}
        customButtonText={customButtonText}
        selectedMonth={String(selectedMonth)}
        handleCarouselChange={handleCarouselChange}
        navigateToPlanReview={navigateToPlanReview}
        handleCustomDownPayment={handleCustomDownPayment}
      />
    );
  };

  const renderNewProductSelectionScreen = () => {
    return <PlanSelection planList={filteredProductCalculations} />;
  };

  return <>{isAlle ? renderAlleScreen() : renderNewProductSelectionScreen()}</>;
};

export default Plans;
