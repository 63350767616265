import React, { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';

import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import {
  isOrganizationDental,
  isOrganizationCompetitivePlastics,
  MINIMUM_AMOUNT,
  TRACK_VERSION,
  WEBPREQUAL,
} from 'lib/constants';

import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Box, Button, Container, Icon, NumberFormatInput, Subtitle, TextField, Title } from 'lib/components';
import { SmallText } from 'pages/Terms/Terms';
import InfoModal from '../modals/InfoModal';
import { useTranslation } from 'react-i18next';
import { isMissingInfoNextButtonDisabled } from 'lib/utils/IsMissingInfoNextButtonDisabled';

export const StatedIncome = ({ saveIVInfo }) => {
  const { t: translate } = useTranslation();
  const { application, organization, sessionApiData } = useStore.getState() || {};
  const { trackSegmentEvent } = useSegment();

  const { missingInfo } = sessionApiData || {};
  const initialStatedIncomeInfo = missingInfo?.find((data) => data?.key === 'STATED_INCOME');

  const [showModal, setShowModal] = useState(false);
  const [statedIncome, setStatedIncome] = useState<number | null>(initialStatedIncomeInfo?.value);

  useEffect(() => {
    trackSegmentEvent('cherry_access_income_viewed', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (statedIncome !== null) {
        setShowModal(true);
      }
    }
  };

  const onAmountApproved = () => {
    saveIVInfo(String(statedIncome));
  };

  const hideModal = () => setShowModal(false);

  const next = () => {
    if (statedIncome !== null && (statedIncome < 15000 || statedIncome > 250000)) {
      setShowModal(true);
    } else {
      saveIVInfo(String(statedIncome));
    }
  };

  const onChange = (e) => {
    const value = e?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      setStatedIncome(Number(value));
    } else {
      setStatedIncome(null);
    }
  };

  const isNextButtonDisabled = isMissingInfoNextButtonDisabled(statedIncome);

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'union_cli'} />
        {isOrganizationDental(organization?.industry) || isOrganizationCompetitivePlastics(organization?.industry) ? (
          <>
            <Title m={'10px 0px 8px 0px'} data-testid="stated-income-title">
              {translate('cherryAccess.statedIncome.title.annualHouseholdIncome')}
            </Title>
            <Subtitle
              m={'0px 0px 24px 0px'}
              dangerouslySetInnerHTML={{
                __html: translate('cherryAccess.statedIncome.subTitle.annualHouseholdIncome'),
              }}
            />
          </>
        ) : (
          <>
            <Title
              m={'10px 0px 8px 0px'}
              data-testid="stated-income-title"
              dangerouslySetInnerHTML={{
                __html: translate('cherryAccess.statedIncome.title.individualAnnualIncome'),
              }}
            />
            <Subtitle
              m={'0px 0px 24px 0px'}
              dangerouslySetInnerHTML={{
                __html: translate('cherryAccess.statedIncome.subTitle.individualAnnualIncome'),
              }}
            />
          </>
        )}

        <Box>
          <TextField
            id="annual-income"
            data-testid="annual-income"
            data-neuro-label="annual-income"
            variant="filled"
            label={
              isOrganizationDental(organization?.industry) || isOrganizationCompetitivePlastics(organization?.industry)
                ? translate('cherryAccess.statedIncome.label.annualHouseholdIncome')
                : translate('cherryAccess.statedIncome.label.individualAnnualIncome')
            }
            type="tel"
            value={statedIncome}
            max={application?.creditLineMaxEligible}
            min={application?.balanceAvailable ? application?.balanceAvailable : MINIMUM_AMOUNT}
            onChange={onChange}
            onKeyDown={checkKeyDown}
            InputProps={{
              inputComponent: NumberFormatInput,
              pattern: '[^0-9.]',
              startAdornment: (
                <InputAdornment
                  sx={{
                    padding: '1px 0 0 0',
                    '& .MuiTypography-root': { color: '#000' },
                  }}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={isNextButtonDisabled}>
          {translate('buttons.continue')}
        </Button>
        <SmallText>{translate('cherryAccess.statedIncome.footerText')}</SmallText>
      </ButtonContainer>
      {showModal && (
        <InfoModal
          show={showModal}
          hideModal={hideModal}
          onApproved={onAmountApproved}
          type={'STATED_INCOME'}
          amount={statedIncome}
        />
      )}
    </Container>
  );
};
