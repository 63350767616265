import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { DENTAL_TREATMENT_DATA_GATHERING, DUE_DATE_ALIGNMENT } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { FormatAmount } from 'lib/utils';
import { useConfirm } from './services';

import { Button, CheckoutProgressBar, Container, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useSentry } from 'lib/hooks/useSentry';
import { useServices } from 'pages/AuthChecker/services';
import AlleConfirm from './AlleConfirm';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { ThreeDFrame } from './components/ThreeDIFrame';
import SummaryCard from './components/SummaryCard';
import { theme } from 'config/theme';

const attempt = 0;
let pollingAttempt = 0;

const Confirm = () => {
  const alert = useAlert();
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { postConfirmLoan } = useConfirm();
  const { getLoan, getFundedContracts } = useServices();
  const { t: translate } = useTranslation();
  const [paymentIdForThreeD, setPaymentIdForThreeD] = useState<string | null>(null);

  const {
    loan,
    selectedPlan,
    setPageIndex,
    features,
    borrower,
    flowType,
    sessionData,
    isAlle,
    sessionApiData,
    setDeclinedCardError,
  } = useStore();
  const { captureException } = useSentry();
  const { flags } = useFlags();
  const [loading, setLoading] = useState<boolean>(false);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { featureFlags } = sessionApiData || {};

  useEffect(() => {
    setPageIndex(7);
  }, [setPageIndex]);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutFinalAgreementLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    getLoanFinal?.();
  }, []);

  const getLoanFinal = async () => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;

    try {
      setLoading(true);
      if (loanId) {
        await getLoan(loanId);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);

      captureException('Confirm Plan Error', {
        error: err,
        page: 'Confirm Plan',
        message: 'Product Calculation Error',
        loanId: loanId || 'No Loan',
      });
    }
  };

  const getFundedContractCount = async () => {
    try {
      setLoading(true);
      const fundedContracts = await getFundedContracts();
      setLoading(false);
      return fundedContracts;
    } catch (err) {
      setLoading(false);
      captureException(err, { message: `Get Loan List Error in Confirm Page` });
      return null;
    }
  };

  const postConfirmLoanStatusHandler = async (res) => {
    if (res?.message === 'loan.status.invalid') {
      handleLoanStatusInvalid();
    } else if (res?.message === 'loan.down_payment_failed.unprocessable') {
      showDPFailError();
      return;
    } else if (res?.status === 'AWAITING_FUNDING') {
      const dueDateAlignmentActive = features?.includes(DUE_DATE_ALIGNMENT);
      const fundedContracts = await getFundedContractCount();

      const doesAnyFundedContractExist =
        fundedContracts?.contents?.some(
          ({ paymentSchedules }) => dayjs(paymentSchedules?.[0]?.dueAt).format('DD') !== dayjs().format('DD'),
        ) && fundedContracts?.total >= 1;

      if (dueDateAlignmentActive && doesAnyFundedContractExist) {
        navigate('due-date-alignment');
      } else {
        const postCheckoutSurvey = flags?.APPROVALSURVEY?.postCheckout?.isEnabled();

        const preFundingSurvey = flags?.SURVEY?.preFunding?.isEnabled();
        const hasDataGathering = features?.includes(DENTAL_TREATMENT_DATA_GATHERING);
        let navigateKey = '';
        if (flowType === 'PointOfSalesFlow') {
          const hasRemainingBalance = sessionData?.treatmentAmount > selectedPlan?.grossAmount;
          navigateKey = hasRemainingBalance ? 'pos-success' : 'success';
        } else if (preFundingSurvey) {
          navigateKey = 'survey';
        } else if (postCheckoutSurvey) {
          navigateKey = 'survey-offer';
        } else if (hasDataGathering) {
          navigateKey = 'data-gathering';
        } else {
          navigateKey = 'success';
        }

        if (navigateKey !== '') {
          navigate(navigateKey);
        }
      }
    } else if (res?.status === 'INVALID') {
      handleLoanStatusInvalid();
    } else {
      alert.info(translate('confirm.error.default'));
      captureException('Confirm Error', { message: `Confirm Error`, loan: loan, res: res, borrower: borrower });
    }
  };

  const postThreeD = async (status: boolean) => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;
    const loanData = await getLoan(loanId);
    try {
      if (status) {
        if (loanData?.status === 'REVIEW' && pollingAttempt < 10) {
          setTimeout(() => {
            pollingAttempt = pollingAttempt + 1;
            postThreeD(true);
          }, 2000);
        } else {
          postConfirmLoanStatusHandler(loanData);
        }
      } else if (loanData?.downPaymentAttempt === 1) {
        showDPFailError();
      } else {
        handleLoanStatusInvalid();
      }
    } catch (error) {
      handleLoanStatusInvalid();
    }
  };

  const navigateToSuccess = async () => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;

    try {
      trackEvent(AnalyticEventNames.CWP_LINK_ACCOUNT);
      setLoading(true);

      const loanData = await getLoan(loanId);
      sendActionSegmentEvent(WPQSegmentNames.finalAgreementConfirmClicked);
      if (loanData?.status === 'CANCELLED') {
        navigate(`expired`);
        setLoading(false);
        return;
      } else if (loanData?.status === 'INVALID') {
        handleLoanStatusInvalid();
      }

      const res = await postConfirmLoan(loanId);

      if (res?.threeD) {
        window.scrollTo(0, 0);
        setPaymentIdForThreeD(res?.paymentId);
      } else {
        postConfirmLoanStatusHandler(res);
      }

      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setLoading(false);
      if (e?.status === 401) {
        if (attempt > 0) {
          navigate('approval');
        } else {
          navigate(`loan-cancelled`);
        }
        return;
      } else if (e?.error?.message === 'loan.status.invalid') {
        navigate(`loan-cancelled`);
      } else if (e?.error?.message === 'loan.down_payment_failed.unprocessable') {
        showDPFailError();
      } else {
        alert.info(translate('confirm.error.defaultLarge'));
        captureException(e, { message: `Confirm Error`, loan: loan, borrower: borrower });
      }
    }
  };

  const handleLoanStatusInvalid = () => {
    navigate(`loan-cancelled`);
    setLoading(false);
    return;
  };

  const showDPFailError = () => {
    setDeclinedCardError(true);
    navigate(`add-card`);
  };

  if (isAlle) {
    return (
      <>
        {paymentIdForThreeD ? (
          <IFrameContainer>
            <ThreeDFrame paymentId={paymentIdForThreeD} onConnect={postThreeD} handleClose={setPaymentIdForThreeD} />
          </IFrameContainer>
        ) : (
          <AlleConfirm selectedPlan={selectedPlan} loading={loading} navigateToSuccess={navigateToSuccess} />
        )}
      </>
    );
  }

  return (
    <>
      {!paymentIdForThreeD && (
        <Container showBackButton={true} backUrl={`method-list`}>
          <ContentContainer>
            <CheckoutProgressBar />
            <Subtitle
              m={'10px 0px 24px 0px'}
              color={theme.main.midnightBlue}
              dangerouslySetInnerHTML={{
                __html: translate('confirm.title', {
                  amount: FormatAmount(selectedPlan?.grossAmount),
                }),
              }}
            ></Subtitle>
            <SummaryCard />
          </ContentContainer>

          <ButtonContainer>
            <DisclaimerText>{translate('confirm.subTitle')}</DisclaimerText>
            <Button
              onClick={navigateToSuccess}
              icon={loading ? 'check_bold_gray' : 'check_bold'}
              loading={loading}
              disabled={loading}
            >
              {translate('buttons.agreeAndConfirm')}
            </Button>
          </ButtonContainer>
        </Container>
      )}
      {paymentIdForThreeD && (
        <IFrameContainer>
          <ThreeDFrame paymentId={paymentIdForThreeD} onConnect={postThreeD} handleClose={setPaymentIdForThreeD} />
        </IFrameContainer>
      )}
    </>
  );
};

const StyledTitle = styled(Title)`
  strong {
    color: ${(props) => props.theme.main.green};
  }
`;

const IFrameContainer = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 101;
`;

const DisclaimerText = styled.div`
  color: ${(props) => props.theme.main.grayColor};
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 24px;
`;

export default Confirm;
