import Rox from 'rox-browser';

enum RoxFlagFreezeLevel {
  UntilLaunch = 'untilLaunch',
}

export enum IVScreenTypes {
  DEFAULT = 'default',
  SINGLE = 'single',
  MULTI = 'multi',
}

export enum LetsGoScreenType {
  ZERO_APR = 'ZERO_APR',
  SOCIAL_PROOF = 'SOCIAL_PROOF',
  FAST_APPLY = 'FAST_APPLY',
  PRACTICE_ENDORSEMENT = 'PRACTICE_ENDORSEMENT',
  NO_IMPACT_TO_CREDIT = 'NO_IMPACT_TO_CREDIT',
  CONTROL = 'CONTROL',
}

export const Flags = {
  MX: {
    isEnabled: new Rox.Flag(false),
  },
  SCREEN: {
    shouldDisplayZeroAPRPage: new Rox.Flag(false),
    letsGoScreenType: new Rox.RoxString(LetsGoScreenType.CONTROL, [
      LetsGoScreenType.ZERO_APR,
      LetsGoScreenType.SOCIAL_PROOF,
      LetsGoScreenType.FAST_APPLY,
      LetsGoScreenType.PRACTICE_ENDORSEMENT,
      LetsGoScreenType.NO_IMPACT_TO_CREDIT,
      LetsGoScreenType.CONTROL,
    ]),
    newApproval: new Rox.Flag(false),
    newApprovalCard: new Rox.Flag(false),
    newDenialUi: new Rox.Flag(false),
    fakeRequestIncreaseExpriment: new Rox.Flag(false),
  },
  IV: {
    screenType: new Rox.RoxString(IVScreenTypes.DEFAULT, [
      IVScreenTypes.DEFAULT,
      IVScreenTypes.SINGLE,
      IVScreenTypes.MULTI,
    ]),
  },
  POLLING: {
    serverSideEvent: new Rox.Flag(false),
  },
  APPROVALSURVEY: {
    approvalScreen: new Rox.Flag(false),
    postCheckout: new Rox.Flag(false),
  },
  SURVEY: {
    preFunding: new Rox.Flag(false),
  },
  DEMO: {
    sandboxNavigationEnabled: new Rox.Flag(false),
  },
};

// Register the feature flags
(Object.keys(Flags) as (keyof typeof Flags)[])?.forEach((namespace) => {
  Rox.register(namespace, Flags[namespace]);
});
