import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Grid } from '@mui/material';
import Confetti from 'react-confetti';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import timezone from 'dayjs/plugin/timezone';
import { useApolloClient, useMutation } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';

import { IApplication } from 'lib/types';
import useStore from 'lib/hooks/useStore';
import { useSentry } from 'lib/hooks/useSentry';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { confettiColors, theme } from 'config/theme';
import { GET_APPLICATION } from 'lib/graphql/queries';
import { useNavigation } from 'lib/hooks/useNavigation';
import { FormatAmount } from 'lib/utils';
import { EXPIRED_DATE_FORMAT_EXPERIMENT } from 'lib/constants';
import { Button, Container, Icon, Loading, Subtitle, Title } from 'lib/components';
import {
  CreditLineIncreaseStatus,
  CreditLineIncreaseSubStatus,
  useCreditLineIncrease,
} from 'lib/hooks/useCreditLineIncrease';
import CreditLineIncreaseModal from './modals/CreditLineIncreaseModal';
import AlleApproval from './AlleApproval';
import MickeyMouseModal from './modals/MickeyMouseModal';
import { PartialApprovalModal } from './modals/PartialApprovalModal';
import { CREATE_LOANS } from 'lib/graphql/mutations';
import { useServices } from 'pages/AuthChecker/services';
import { loanStatusService } from 'pages/AuthChecker/utilityFunctions';
import RevampMickeyMouseModal from './modals/RevampMickeyMouseModal';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import OldApproval from './OldApproval';
import WhatDoesMeanModal from './modals/WhatDoesMeanModal';
import { useViewport } from 'lib/hooks/useViewport';
import RequestLargerApprovalModal from './RequestLargerApprovalModal';
import PreviewPaymentOptionsWidget from './PreviewPaymentOptionsWidget';
import ApprovalCard from './components/ApprovalCard';
import AddWallet from './components/AddWallet';
import SendApprovalEmail from './components/SendApprovalEmail';
import ThanksForClickingModal from './modals/ThanksForClickingModal';

dayjs.extend(utc);
dayjs.extend(timezone);

enum CreditLineStatus {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
}

enum CreditLineItemSources {
  NORMAL = 'NORMAL',
  DEFAULT_HIGH_LINE = 'DEFAULT_HIGH_LINE',
}

enum CreditLineItemStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

const STANDARD_APPROVAL_PAGE_FLOWS = ['ApplyCheckoutFlow', 'PointOfSalesFlow'];

const Approval = () => {
  const { t: translate } = useTranslation();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { submitApplication } = useNeuroId();
  const { isMobile } = useViewport();
  const client = useApolloClient();
  const [createLoanRequest] = useMutation(CREATE_LOANS);
  const { getApplication } = useServices();
  const alert = useAlert();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();

  const {
    selectedLocation,
    setSelectedApplication,
    organization,
    flowType,
    isAlle,
    setApplication,
    sessionData,
    application,
    setLoan,
    preCLIBalance,
    setPreCLIBalance,
  } = useStore();
  const { sessionApiData } = useStore.getState();
  const { featureFlags } = sessionApiData || {};
  const isNewApprovalCardEnable = featureFlags?.newApprovalCard || false;
  const isFakeRequestIncreaseExprimentEnabled = featureFlags?.isFakeRequestIncreaseExprimentEnabled || false;
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [contentsData, setContentsData] = useState<IApplication | null>(null);
  const [showMickeyMouseModal, setShowMickeyMouseModal] = useState(false);
  const [showRevampMickeyMouseModal, setShowRevampMickeyMouseModal] = useState(false);
  const [whatdoesMeanModalVisibility, setWhatdoesMeanModalVisibility] = useState(false);
  const [thanksForClickingModalVisibility, setThanksForClickingModalVisibility] = useState(false);
  const [readyForAnalyticEvents, setReadyForAnalyticEvents] = useState<boolean>(false);
  const [isPartialApprovalModalVisible, setIsPartialApprovalModalVisible] = useState(false);
  const [isRequestLargeApprovalModalVisible, setIsRequestLargeApprovalModalVisible] = useState(false);
  const {
    fetchLatestCreditLineIncrease,
    pollLatestCreditLineIncrease,
    loading,
    creditLineIncrease,
    setShowCreditLineIncreaseModal,
    showCreditLineIncreaseModal,
  } = useCreditLineIncrease();

  const [creditLineIncreaseItem, setCreditLineIncreaseItem] = useState(
    creditLineIncrease || sessionApiData?.creditLineIncrease,
  );

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const containerRef: any = useRef(null);
  const [confettiActive, setConfettiActive] = useState(false);

  const isEligibleForCreditLineIncrease = contentsData?.creditLineIncrease === CreditLineStatus.ELIGIBLE;

  const IS_UNABLE_TO_REQUEST_INCREASE = !isEligibleForCreditLineIncrease && isFakeRequestIncreaseExprimentEnabled;

  const creditLineIncreaseItemSource = creditLineIncreaseItem?.source;
  const creditLineIncreaseItemStatus = creditLineIncreaseItem?.status;

  const IS_INCREASE_APPROVED =
    (creditLineIncreaseItemSource === CreditLineItemSources.NORMAL ||
      creditLineIncreaseItemSource === CreditLineItemSources.DEFAULT_HIGH_LINE) &&
    creditLineIncreaseItemStatus === CreditLineItemStatus.APPROVED;

  const IS_INCREASE_DENIED =
    creditLineIncreaseItemSource === CreditLineItemSources.NORMAL &&
    creditLineIncreaseItemStatus === CreditLineItemStatus.DENIED;

  const SHOW_CONFETTI = confettiActive && !IS_INCREASE_DENIED;

  const DOES_COMING_FROM_CLI = preCLIBalance !== undefined;

  const getInitialStatus = () => {
    const { applicationFlow } = useStore.getState() || {};
    setConfettiActive(
      dayjs().diff(applicationFlow?.updatedAt, 'minutes') < 135 && applicationFlow?.status === 'APPROVED',
    );
  };

  const setAnalyticsEvent = () => {
    const { application } = useStore.getState() || {};

    sendLoadSegmentEvent(WPQSegmentNames.approvalPageLoad, {
      approvalAmount: application?.balanceAvailable,
      lineIncreaseEligibility: application?.creditLineIncrease,
    });
  };
  useEffect(() => {
    getInitialStatus();
    setTimeout(() => {
      setConfettiActive(false);
    }, 6000);
  }, []);

  useEffect(() => {
    const { application } = useStore.getState() || {};
    if (application?.id) {
      fetchLatestCreditLineIncrease(application?.id).then((res) => {
        if (res?.id) {
          setCreditLineIncreaseItem(res);
          checkCreditLineIncreaseModalStatus(res);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (contentsData && IS_UNABLE_TO_REQUEST_INCREASE && IS_INCREASE_DENIED && DOES_COMING_FROM_CLI) {
      setIsRequestLargeApprovalModalVisible(true);
    }
  }, [contentsData]);

  const checkCreditLineIncreaseModalStatus = (cLineIncreaseItem) => {
    if (
      [CreditLineIncreaseStatus.REVIEW].includes(cLineIncreaseItem?.status) &&
      ![
        CreditLineIncreaseSubStatus.PROCESSING,
        CreditLineIncreaseSubStatus.MISSING_INFO,
        CreditLineIncreaseSubStatus.APPROVED,
        CreditLineIncreaseSubStatus.MORE,
      ].includes(cLineIncreaseItem?.subStatus) &&
      cLineIncreaseItem?.source === CreditLineItemSources.NORMAL
    ) {
      setShowCreditLineIncreaseModal(true);
    } else {
      setShowCreditLineIncreaseModal(false);
    }
  };

  useEffect(() => {
    const { application } = useStore.getState();
    checkCreditLineIncreaseModalStatus(creditLineIncreaseItem);
    if (application?.id) {
      getApplicationsWithSearch?.(application?.id);
    }
    submitApplication('approval');
  }, []);

  useEffect(() => {
    if (readyForAnalyticEvents) {
      setAnalyticsEvent();
    }
  }, [readyForAnalyticEvents]);

  const hideCreditLineIncreaseModal = () => setShowCreditLineIncreaseModal(false);

  const hideMickeyMouseModal = () => setShowMickeyMouseModal(false);
  const hideRevampMickeyMouseModal = () => setShowRevampMickeyMouseModal(false);
  const hideWhatDoesMeanModal = () => setWhatdoesMeanModalVisibility(false);
  const hideThanksForClicking = () => setThanksForClickingModalVisibility(false);

  const getApplicationsWithSearch = async (applicationId: string) => {
    setContentLoading(true);
    const { applicationFlow } = useStore.getState() || {};
    try {
      if (applicationId) {
        const { data } = await client.query({
          query: GET_APPLICATION,
          variables: {
            input: {
              applicationId,
            },
          },
        });

        if (data?.getApplication?.id) {
          const response = data.getApplication;
          setContentsData(response);
          setSelectedApplication(response);
          setContentLoading(false);
          setReadyForAnalyticEvents(true);
          setApplication(response);
          if (
            response.mickeyMouse &&
            response.creditLineIncrease === CreditLineStatus.ELIGIBLE &&
            applicationFlow.requestAmount > response.balanceAvailable
          ) {
            setShowRevampMickeyMouseModal(true);
          }
          return;
        } else {
          setContentLoading(false);
        }
      } else {
        throw new Error('Approval Page - application id not found.');
      }
    } catch (err) {
      captureException(err, {
        message: 'Approval Page - fetch application list with search error',
        error: err,
      });
      setContentLoading(false);
    }
  };

  const requestCreditLineIncrease = async () => {
    if (contentsData?.mickeyMouse) {
      setShowMickeyMouseModal(true);
      sendActionSegmentEvent(WPQSegmentNames.mickeyMouseRequestIncreaseClicked);
    } else if (IS_UNABLE_TO_REQUEST_INCREASE) {
      sendActionSegmentEvent(WPQSegmentNames.requestIncreaseClicked);
      setIsRequestLargeApprovalModalVisible(true);
    } else {
      sendActionSegmentEvent(WPQSegmentNames.requestIncreaseClicked);
      setPreCLIBalance(contentsData?.balanceAvailable);
      await pollLatestCreditLineIncrease('APPROVAL_SCREEN');
    }
  };

  const iconName = () => {
    return `party`;
  };

  const hideRequestLargeApprovalModal = () => {
    setIsRequestLargeApprovalModalVisible(false);
  };

  const cliResultText = () => {
    if (IS_INCREASE_APPROVED) {
      return (
        <Subtitle color={theme.main.midnightBlue} m={'0px'}>
          {translate('approval.cli.fakeRequestExperiment.success', {
            previousBalance: FormatAmount(preCLIBalance, { omitDecimal: true }),
            balanceAvailable: FormatAmount(application?.balanceAvailable, { omitDecimal: true }),
          })}
        </Subtitle>
      );
    }

    if (IS_INCREASE_DENIED) {
      return (
        <Subtitle color={theme.main.midnightBlue} m={'0px'}>
          {translate('approval.cli.fakeRequestExperiment.denied')}
        </Subtitle>
      );
    }

    return null;
  };

  const posCheckoutHandler = async () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalContinueClicked);
    if (flowType === 'PointOfSalesFlow') {
      navigate('pos-checkout');
    } else if (flowType === 'ApplyCheckoutFlow') {
      setContentLoading(true);
      const params = {
        merchantId: selectedLocation?.id,
        applicationId: application?.id,
        purchaseAmount: Math.min(sessionData.treatmentAmount, Number(contentsData?.balanceAvailable)),
        selfCheckout: true,
        channel: 'APPLY_CHECKOUT',
      };
      const { data } = await createLoanRequest({ variables: { input: { ...params } } });
      if (data.createLoan?.data) {
        await getApplication(data.createLoan?.data?.applicationId);
        setLoan(data?.createLoan?.data);
        loanStatusService(data.createLoan?.data, organization?.slug);
      } else {
        alert.info(`${translate('approval.error.default')}`);
      }
      setContentLoading(false);
      navigate(`choose-plan`);
    }
  };

  const handleExperimentContinue = () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalContinueClicked);
    navigate('what-happens-next');
  };

  const openWhatDoesMeanModal = () => {
    sendActionSegmentEvent(WPQSegmentNames.whatDoesThisMeanClicked);
    setWhatdoesMeanModalVisibility(true);
  };

  const closePartialApprovalModal = () => {
    setIsPartialApprovalModalVisible(false);
  };

  const openPartialApprovalModal = () => {
    setIsPartialApprovalModalVisible(true);
  };

  const formattedDate = dayjs(contentsData?.expireAt).format(EXPIRED_DATE_FORMAT_EXPERIMENT);

  if (contentLoading) {
    return (
      <CenterLoading>
        <Loading size={40} />
      </CenterLoading>
    );
  }

  if (isAlle) {
    return <AlleApproval />;
  }
  return (
    <>
      {isFakeRequestIncreaseExprimentEnabled ? (
        <>
          <Container showBackButton={false}>
            {SHOW_CONFETTI ? (
              <ConfettiContainer>
                <Confetti
                  width={600}
                  height={containerRef.current?.clientHeight}
                  numberOfPieces={300}
                  colors={confettiColors}
                  recycle={false}
                />
              </ConfettiContainer>
            ) : null}
            <ContentContainer isMobile={isMobile}>
              <InnerContainer>
                <ApprovedContainer>
                  <Icon src={iconName()} />
                  <Title fontSize="24px" m={'0px 0px 0px 16px'}>
                    {translate('approval.fakeRequestExperiment.title')}
                  </Title>
                </ApprovedContainer>
                {cliResultText()}
              </InnerContainer>
              {isNewApprovalCardEnable ? (
                <>
                  <ApprovalCardDescription>
                    <Trans
                      i18nKey="approvalCard.subtitle"
                      values={{ name: organization?.name }}
                      components={{
                        bold: <strong />,
                      }}
                    ></Trans>
                  </ApprovalCardDescription>
                  <ApprovalCard expireAt={contentsData?.expireAt} balanceAvailable={contentsData?.balanceAvailable} />
                  <AddWallet openThanksModal={setThanksForClickingModalVisibility} page="approval" />
                  <SendApprovalEmail openThanksModal={setThanksForClickingModalVisibility} page="approval" />
                </>
              ) : (
                <ApprovalContainer isMobile={isMobile} isExperimental>
                  {IS_INCREASE_DENIED || IS_INCREASE_APPROVED ? (
                    <MaximumApprovalContainer>
                      <MaximumApprovalText>{translate('approval.cli.maximumApproval')}</MaximumApprovalText>
                      <Icon src={'circle_check'} width={16} height={16} />
                    </MaximumApprovalContainer>
                  ) : (
                    <ApprovalText bold size="12">
                      {translate('approval.upTo')}
                    </ApprovalText>
                  )}

                  <ApprovalText bold size="36">
                    {contentsData?.balanceAvailable &&
                      FormatAmount(contentsData?.balanceAvailable, { omitDecimal: true })}
                  </ApprovalText>
                  <ExpireDate>
                    {translate('approval.expires')} {formattedDate}
                  </ExpireDate>
                  {creditLineIncreaseItem?.status === 'REVIEW' &&
                    !STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) &&
                    !application?.mickeyMouse && (
                      <CreditLineIncreaseReview>
                        {translate('approval.cli.review', {
                          requested: FormatAmount(creditLineIncreaseItem?.amount?.requested),
                        })}
                      </CreditLineIncreaseReview>
                    )}
                  <InfoIconContainer>
                    <Icon
                      src={'info-circle-outline-green'}
                      width={24}
                      height={24}
                      hover
                      onClick={openWhatDoesMeanModal}
                    />
                  </InfoIconContainer>
                </ApprovalContainer>
              )}

              <PreviewPaymentOptionsWidget />

              {flowType === 'ApplyCheckoutFlow' ? (
                Number(contentsData?.balanceAvailable) >= sessionData.treatmentAmount ? (
                  <Grid container alignItems="center" wrap="nowrap" gap="8px">
                    <Icon height={24} width={24} src="circle_check_outline_black" />
                    <Subtitle>
                      {translate('fullyCoversYou', {
                        treatmentAmount: FormatAmount(sessionData.treatmentAmount),
                      })}
                    </Subtitle>
                  </Grid>
                ) : (
                  <Grid direction="column" container gap="16px" mt={5}>
                    <Subtitle>
                      {translate('approval.partOfYour', {
                        treatmentAmount: FormatAmount(sessionData.treatmentAmount),
                      })}
                    </Subtitle>
                    <Grid container item alignItems="center" wrap="nowrap" gap="8px">
                      <Icon height={24} width={24} src="help_circle_fill" />
                      <Subtitle cursor="pointer" onClick={openPartialApprovalModal}>
                        <u>{translate('approval.partialApproval')}</u>
                      </Subtitle>
                    </Grid>

                    <PartialApprovalModal show={isPartialApprovalModalVisible} hideModal={closePartialApprovalModal} />
                  </Grid>
                )
              ) : null}

              {STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) ? (
                <>
                  <ButtonContainer isMobile={isMobile}>
                    <Button onClick={posCheckoutHandler}>{translate('buttons.continue')}</Button>
                  </ButtonContainer>
                </>
              ) : (
                <>
                  <StickyButtonContainer isMobile={isMobile}>
                    <Button onClick={handleExperimentContinue}>{translate('buttons.continue')}</Button>
                    <Button
                      secondary={true}
                      onClick={requestCreditLineIncrease}
                      loading={loading}
                      disabled={loading || IS_INCREASE_APPROVED}
                    >
                      {translate('buttons.requestLargerApproval')}
                    </Button>
                  </StickyButtonContainer>

                  {showCreditLineIncreaseModal ? (
                    <CreditLineIncreaseModal
                      show={showCreditLineIncreaseModal}
                      hideModal={hideCreditLineIncreaseModal}
                      creditLineIncrease={creditLineIncreaseItem}
                    />
                  ) : null}
                </>
              )}
            </ContentContainer>

            {showMickeyMouseModal ? (
              <MickeyMouseModal show={showMickeyMouseModal} hideModal={hideMickeyMouseModal} />
            ) : null}
            {showRevampMickeyMouseModal ? (
              <RevampMickeyMouseModal show={true} hideModal={hideRevampMickeyMouseModal} />
            ) : null}
            {whatdoesMeanModalVisibility ? <WhatDoesMeanModal show={true} hideModal={hideWhatDoesMeanModal} /> : null}
            <RequestLargerApprovalModal
              application={contentsData}
              show={isRequestLargeApprovalModalVisible}
              hideModal={hideRequestLargeApprovalModal}
            />
            {thanksForClickingModalVisibility ? (
              <ThanksForClickingModal show={true} hideModal={hideThanksForClicking} />
            ) : null}
          </Container>
        </>
      ) : (
        <OldApproval />
      )}
    </>
  );
};

export default Approval;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ApprovedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CenterLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfettiContainer = styled.div`
  position: absolute;
  margin-left: -20px;
`;

const ApprovalContainer = styled.div<{ isMobile: boolean; isExperimental?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 0;
  margin-top: ${(props) => (props.isExperimental ? '16px' : '24px')};
  padding: ${(props) => (props.isExperimental ? '16px 24px' : '24px')};
  border-radius: 24px;
  opacity: 0px;
  border: ${(props) => (!props?.isMobile ? '2px solid #e7e9ea' : '0px')};
  box-shadow: 0 1.23656px 2.30138px 0 rgba(0, 0, 0, 0.02), 0 3.12736px 5.82036px 0 rgba(0, 0, 0, 0.03),
    0 6.37951px 11.87298px 0 rgba(0, 0, 0, 0.04), 0 13.14059px 24.4561px 0 rgba(0, 0, 0, 0.05),
    0 36px 67px 0 rgba(0, 0, 0, 0.07);
  background-color: ${(props) => (props.isExperimental ? theme.main.white : 'unset')};
`;

const ApprovalCardDescription = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 8px 0px 24px;
`;

const ContentContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 24px 24px 0px 24px;
  margin-top: 0px;
  background-color: ${(props) => (props?.isMobile ? theme.main.whiteGray : theme.main.white)};
`;

const ButtonContainer = styled.div<{ isMobile?: boolean }>`
  background-color: ${(props) => (props?.isMobile ? theme.main.whiteGray : theme.main.white)};
  padding: 0px 24px 14px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: ${(props) => (props?.isMobile ? 'none' : 'inset 0 1px 0 #dadada')};
  padding-top: ${(props) => (props?.isMobile ? '0px' : '24px')};
`;

const StickyButtonContainer = styled.div<{ isMobile?: boolean }>`
  background-color: ${theme.main.white};
  padding: 0px 24px 14px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: inset 0 1px 0 #dadada;
  padding-top: 24px;
  position: sticky;
  bottom: 0;
  margin: 0 -24px 0;
  z-index: 1;
`;

const CreditLineIncreaseReview = styled.span`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-top: 8px;
`;

const ApprovalText = styled.span<{ bold?: boolean; size: string; marginTop?: string }>`
  font-size: ${(props) => `${props?.size}px`};
  font-weight: ${(props) => (props?.bold ? 700 : 400)};
  text-align: center;
  margin-top: ${(props) => `${props?.marginTop}px`};
  letter-spacing: 0.6px;
`;

const MaximumApprovalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  padding: 4px 4px 4px 8px;
  border-radius: 16px;
  background: ${theme.main.lightGreen};
`;

const MaximumApprovalText = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: ${theme.main.green};
`;

const ExpireDate = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: center;
  color: ${theme.main.grey20};
`;

const InfoIconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;
`;
