import { InputAdornment } from '@mui/material';
import {
  Box,
  Button,
  Container,
  Icon,
  NumberFormatInput,
  RadioButtonGroup,
  Subtitle,
  TextField,
  Title,
} from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { IncomeTypeEnum, isOrganizationCompetitivePlastics, isOrganizationDental, WEBPREQUAL } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InfoModal from '../modals/InfoModal';
import CancelModal from './CancelModal';
import useStore from 'lib/hooks/useStore';
import { useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';
import { useTranslation } from 'react-i18next';
import { SmallText } from 'pages/Terms/Terms';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { isMissingInfoNextButtonDisabled } from 'lib/utils/IsMissingInfoNextButtonDisabled';

export const StatedIncome = ({
  saveIVInfo,
  onCancelModalApprovedClick,
}: {
  saveIVInfo: (ivInfo: string) => void;
  onCancelModalApprovedClick: () => void;
}) => {
  const { t: translate } = useTranslation();
  const { trackSegmentEvent } = useSegment();
  const { application, borrower, organization } = useStore.getState() || {};
  const { createLoading } = useCreditLineIncrease();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [statedIncome, setStatedIncome] = useState<number | null>(null);
  const [radioValue, setRadioValue] = useState<IncomeTypeEnum | null>(null);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.highLineStatedIncomeLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const toggleCancelModal = () => setShowCancelModal(!showCancelModal);

  const onCancelApproved = () => {
    trackSegmentEvent('Canceled Line Increase', {
      borrowerId: borrower?.id,
      applicationId: application?.id,
      cancelScreen: 'Stated Income',
      application: WEBPREQUAL,
    });
    setShowCancelModal(false);
    onCancelModalApprovedClick();
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (statedIncome !== null) {
        setShowModal(true);
      }
    }
  };

  const onAmountApproved = () => {
    if (statedIncome !== null) {
      sendActionSegmentEvent(WPQSegmentNames.highLineStatedIncomeContinue, {
        borrowerIncomeCategory:
          isOrganizationDental(organization?.industry) || isOrganizationCompetitivePlastics(organization?.industry)
            ? 'Household'
            : 'Individual',
        incomeType: radioValue,
      });
      saveIVInfo(String(statedIncome * (radioValue === IncomeTypeEnum.MONTHLY ? 12 : 1)));
    }
  };

  const hideModal = () => setShowModal(false);

  const next = () => {
    if (radioValue && statedIncome !== null) {
      setShowModal(true);
    } else {
      saveIVInfo(String(statedIncome));
    }
  };

  const onChange = (e) => {
    const value = e?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      setStatedIncome(Number(value));
    } else {
      setStatedIncome(null);
    }
  };

  const onChangeHandler = (e) => {
    setRadioValue(e?.target?.value);
  };

  const isNextButtonDisabled = isMissingInfoNextButtonDisabled(statedIncome, createLoading);

  return (
    <Container>
      <ContentContainer>
        <Icon src={'union_cli'} />
        {isOrganizationDental(organization?.industry) || isOrganizationCompetitivePlastics(organization?.industry) ? (
          <>
            <Title m={'10px 0px 8px 0px'} data-testid="stated-income-title">
              {translate('cherryAccess.statedIncome.title1')}
            </Title>
            <Subtitle
              m={'0px 0px 24px 0px'}
              dangerouslySetInnerHTML={{
                __html: translate('cherryAccess.statedIncome.subTitle1'),
              }}
            />
          </>
        ) : (
          <>
            <Title m={'10px 0px 8px 0px'} data-testid="stated-income-title">
              {translate('cherryAccess.statedIncome.title2')}
            </Title>
            <Subtitle
              m={'0px 0px 24px 0px'}
              dangerouslySetInnerHTML={{
                __html: translate('cherryAccess.statedIncome.subTitle2'),
              }}
            />
          </>
        )}
        <StyledBox>
          <RadioButtonGroup
            onChange={onChangeHandler}
            radioButtonOptions={[
              {
                textValue: IncomeTypeEnum.MONTHLY,
                radioLabel: translate('cherryAccess.statedIncome.monthly'),
              },
              {
                textValue: IncomeTypeEnum.ANNUAL,
                radioLabel: translate('cherryAccess.statedIncome.annual'),
              },
            ]}
            value={radioValue || ''}
          />
        </StyledBox>
        {!!radioValue ? (
          <Box>
            <TextField
              id="annual-income"
              data-testid="annual-income"
              data-neuro-label="annual-income"
              variant="filled"
              label={
                isOrganizationDental(organization?.industry) ||
                isOrganizationCompetitivePlastics(organization?.industry)
                  ? translate('lineExpansion.statedIncome.householdIncome', {
                      radioValue: translate(`lineExpansion.statedIncome.${radioValue.toLowerCase()}`),
                    })
                  : translate('lineExpansion.statedIncome.householdIncome', {
                      radioValue: translate(`lineExpansion.statedIncome.${radioValue.toLowerCase()}`),
                    })
              }
              type="tel"
              value={statedIncome}
              max={application?.creditLineMaxEligible}
              min={application?.balanceAvailable}
              onChange={onChange}
              onKeyDown={checkKeyDown}
              onFocus={() => {
                sendActionSegmentEvent(WPQSegmentNames.highLineStatedIncomeClicked);
              }}
              onBlur={(e) => {
                sendActionSegmentEvent(WPQSegmentNames.highLineStatedIncomeFilled, {
                  metaData: e.target.defaultValue,
                });
              }}
              InputProps={{
                inputComponent: NumberFormatInput,
                pattern: '[^0-9.]',
                startAdornment: (
                  <InputAdornment
                    sx={{
                      padding: '1px 0 0 0',
                      '& .MuiTypography-root': { color: '#000' },
                    }}
                    position="start"
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ) : null}
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={isNextButtonDisabled} loading={createLoading}>
          {translate('buttons.continue')}
        </Button>
        <LinkText onClick={toggleCancelModal}>{translate('buttons.nevermind')}</LinkText>
        <SmallText>{translate('cherryAccess.statedIncome.footerText')}</SmallText>
      </ButtonContainer>
      {showModal && (
        <InfoModal
          show={showModal}
          hideModal={hideModal}
          onApproved={onAmountApproved}
          type={'STATED_INCOME'}
          amount={statedIncome}
          selectedRadioType={radioValue}
        />
      )}
      {showCancelModal && (
        <CancelModal show={showCancelModal} hideModal={toggleCancelModal} onApproved={onCancelApproved} />
      )}
    </Container>
  );
};

const StyledBox = styled(Box)`
  gap: 8px;
`;

const LinkText = styled.a`
  color: ${(props) => props.theme.main.green};
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
  display: block;
`;
