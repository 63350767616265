import { useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

import { CREDIT_CARD_DOWN_PAYMENT } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { useSentry } from 'lib/hooks/useSentry';
import { filterCardsByCreatedAt, filterPaymentMethods, FormatAmount } from 'lib/utils';
import { useServices } from 'pages/AuthChecker/services';

import { Button, CheckoutProgressBar, Container, Icon, Subtitle } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';

import { usePlans } from './services';
import AllePlanReview from './AllePlanReview';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { PlanReviewCard } from 'pages/Plans/components/PlanReviewCard';
import { useViewport } from 'lib/hooks/useViewport';
import { theme } from 'config/theme';

const PlanReview = () => {
  const { getLoan } = useServices();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { getPaymentMethods, patchLoan, getPaymentMethodFees } = usePlans();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();
  const { isMobile } = useViewport();

  const { loan, setPageIndex, selectedPlan, features, isAlle, sessionApiData } = useStore();
  const amount = loan?.purchaseAmount;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutConfirmationLoad, {
        is_custom_plan: !!selectedPlan?.product?.parentId,
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(2);
  }, [setPageIndex]);

  const navigateToDownPayment = async () => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;
    sendActionSegmentEvent(WPQSegmentNames.planConfirmationClicked, {
      plan_length: selectedPlan?.product?.term,
    });

    try {
      setLoading(true);
      const loanData = await getLoan(loanId);

      if (loanData?.status === 'CANCELLED') {
        navigate(`expired`);
        setLoading(false);
        return;
      }

      await patchLoan({
        loanId: loanId,
        productId: selectedPlan?.product?.id,
      });

      const paymentMethodsResponse = await getPaymentMethods();
      await getPaymentMethodFees();
      const hasCreditCardPaymentActive = features?.includes(CREDIT_CARD_DOWN_PAYMENT);
      const debitCards = filterPaymentMethods(paymentMethodsResponse?.contents, hasCreditCardPaymentActive);
      const filteredDebitCardsByCreatedAt = debitCards?.filter((card) => filterCardsByCreatedAt(card?.storedCard));
      const routeName =
        filteredDebitCardsByCreatedAt && filteredDebitCardsByCreatedAt?.length > 0 ? 'down-payment' : 'add-card';
      navigate(`${routeName}`);
      setLoading(false);
    } catch (err) {
      captureException('Plan Review Error', {
        error: err,
        page: 'Plan Review',
        loanId: loanId || 'No Loan',
      });

      setLoading(false);
      navigate(`loan-cancelled`);
    }
  };

  const chooseAnotherPlanHandler = () => {
    sendActionSegmentEvent(WPQSegmentNames.planConfirmationChooseAnotherClicked);
    navigate(`plans`);
  };

  const renderButton = () => {
    return (
      <>
        <Button onClick={navigateToDownPayment} loading={loading} disabled={loading}>
          {translate('buttons.confirm')}
        </Button>
        <Button onClick={chooseAnotherPlanHandler} secondary={true}>
          {newChangeButton}
        </Button>
      </>
    );
  };

  const customHeaderText = selectedPlan?.product?.parentId ? translate('planReview.customTitle') : '';
  const customButtonText = selectedPlan?.product?.parentId
    ? translate('planReview.customText1')
    : translate('planReview.customText2');
  const newChangeButton = translate('planReview.chooseDifferentPlan');

  if (isAlle) {
    return (
      <AllePlanReview
        customHeaderText={customHeaderText}
        selectedPlan={selectedPlan}
        amount={amount}
        loading={loading}
        customButtonText={customButtonText}
        chooseAnotherPlanHandler={chooseAnotherPlanHandler}
        navigateDownPayment={navigateToDownPayment}
      />
    );
  }

  return (
    <>
      <Container showBackButton={true} backUrl={`plans`}>
        <CustomContentContainer justify="space-between" IsMobile={isMobile} IsNewProductScreen>
          <CheckoutProgressBar />
          <InnerContainer>
            <Subtitle
              m={'10px 0px 16px 0px'}
              color={theme.main.midnightBlue}
              dangerouslySetInnerHTML={{
                __html: translate('planReview.newSubtitle', {
                  text: customHeaderText?.toLowerCase(),
                  term: selectedPlan?.product?.term,
                  amount: FormatAmount(amount),
                }),
              }}
            />
            <SoloContainer>
              <PlanReviewCard data={selectedPlan} />
            </SoloContainer>
          </InnerContainer>
          {selectedPlan?.promo && selectedPlan?.product?.promoApr !== null ? (
            <InnerContainer>
              <InfoContainer>
                <Icon width={20} height={20} src={'info_circle_outline'} />

                <InfoText
                  dangerouslySetInnerHTML={{
                    __html: translate('planReview.info', {
                      apr: selectedPlan?.product?.fallbackApr || selectedPlan?.product?.apr,
                    }),
                  }}
                />
              </InfoContainer>
            </InnerContainer>
          ) : null}
        </CustomContentContainer>
        {!isMobile && <ButtonContainer>{renderButton()}</ButtonContainer>}
      </Container>
      {isMobile && <StickyButton>{renderButton()}</StickyButton>}
    </>
  );
};

export default PlanReview;

const CustomContentContainer = styled(ContentContainer)<{ IsMobile: boolean; IsNewProductScreen: boolean }>`
  padding-bottom: ${(props) => (props?.IsMobile && props?.IsNewProductScreen ? '175px' : '0px')};
`;

const StickyButton = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 0px;
  position: absolute;
  width: -webkit-fill-available;
  background-color: white;
  padding: 24px 24px 24px 24px;
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
`;

const SoloContainer = styled.div`
  margin: auto auto 24px;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    margin: unset;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
