import { useTranslation } from 'react-i18next';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { theme } from 'config/theme';
import styled from 'styled-components';
import { useEffect } from 'react';

export const CheckoutProgressBar = () => {
  const { t: translate } = useTranslation();

  const [activeStep, setActiveStep] = React.useState(0);
  const pathname = location?.pathname.split('/')[2];

  useEffect(() => {
    switch (pathname) {
      case 'plans':
      case 'plan-review':
        setActiveStep(0);
        break;
      case 'down-payment':
      case 'add-card':
      case 'method-list':
        setActiveStep(1);
        break;
      case 'confirm':
        setActiveStep(2);
        return;
      default:
        break;
    }
  }, [pathname]);

  const steps = [
    translate('checkoutProgressBar.choosePlan'),
    translate('checkoutProgressBar.addPayment'),
    translate('checkoutProgressBar.confirm'),
    translate('checkoutProgressBar.done'),
  ];
  const green = theme.main.greenMain;

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 12,
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: green,
        borderTopStyle: 'solid',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: green,
        borderTopStyle: 'solid',
      },
    },
  }));

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        sx={{
          '.MuiSvgIcon-root:not(.Mui-completed)': {
            color: 'white',
            borderRadius: '50%',
            border: `1px solid ${theme.main.blueLight30}`,
          },
          '.MuiStepConnector-line': {
            borderLeftStyle: 'dashed',
          },
          '.MuiStep-root': {
            paddingLeft: '5px',
            paddingRight: '5px',
          },
        }}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <CustomStep key={`step.label`} order={index} activeStep={activeStep}>
            <StepLabel>
              <StepperLabel order={index} activeStep={activeStep}>
                {label}
              </StepperLabel>
            </StepLabel>
          </CustomStep>
        ))}
      </Stepper>
    </Stack>
  );
};

const stepTextColor = (index, activeStep) => {
  if (index > activeStep) {
    return theme.main.blueLight30;
  } else {
    return theme.main.midnightBlue;
  }
};

const StepperLabel = styled.span<{ order: number; activeStep: number }>`
  color: ${(props) => stepTextColor(props.order, props.activeStep)} !important;
  font-family: 'Open Sans' !important;
  margin-top: 4px;
`;

const CustomStep = styled(Step)<{ order: number; activeStep: number }>`
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 10px;
    margin-top: 4px;
  }
  .MuiStepContent-root {
    border-top-style: dashed;
  }
  .MuiStepConnector-line {
    border-top-style: dashed;
  }
  .MuiStepLabel-root.Mui-disabled {
    text {
      fill: ${theme.main.blueLight30};
    }
  }
  .MuiStepLabel-root {
    .Mui-completed {
      color: ${theme.main.greenMain};
    }
    .Mui-active {
      color: ${theme.main.midnightBlue} !important;
      font-family: 'Open Sans', serif;
      border: 1px solid white !important;
      font-weight: 700 !important;
      .MuiStepIcon-text {
        fill: 'white !important';
        font-size: 12px !important;
        font-weight: 700;
        font-family: 'Open Sans', serif;
      }
    }
  }
  .MuiStepLabel-label.Mui-completed {
    font-size: 10px;
    font-weight: 400;
    font-family: 'Open Sans', serif;
    margin-top: 6px;
  }
  .MuiStepLabel-label.Mui-active {
    font-size: 10px !important;
    font-family: 'Open Sans', serif;
    margin-top: 3px;
  }
`;
